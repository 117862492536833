







































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'ConfirmationDialog'})
export default class ConfirmationDialog extends Vue {
  @Prop()
  value!: boolean;

  @Prop()
  loading!: boolean;

  @Prop({default: '600px'})
  maxWidth!: string;

  @Emit('input')
  toggleVisibility() {
    return !this.value;
  }
}
